<template>
  <div class="grid">
    <div
        v-for="suit in suits"
        :key="suit"
        class="suit-bg item shadow"
        :class="suit"
        @click="$emit('select', suit)">
      <span>{{ $t('suits.' + suit) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    suits: Array,
  }
}
</script>

<style scoped>
.grid {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  max-height: 70%;
}
.item {
  font-size: large;
  min-width: 30%;
  margin: 1% 1% 1% 1%;
  padding: 1%;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}
</style>