<template>
  <div class="wood-background" style="max-width: 100%;">
    <div class="heading-area">
      <formatted class="heading" style="font-size: smaller;" :code="'relation.' + card.name" :params="{card}"/>
    </div>
    <div class="content-area">
      <div v-if="selectableCards.length === 0"
           class="horizontal-center">
        <formatted code="noSuitableCardForRelation" :params="{card}"/>
        <div class="button"
             @click="$router.go(-1)">
          {{ $t('back') }}
        </div>
      </div>
      <card-selector
          style="height: 100%; min-height: 100%; max-width: 90%; margin: auto;"
          v-if="!selectingSuit"
          :cards="selectableCards"
          :show-suit-menu="showSuitMenuInCardSelector"
          @select="relatedCardSelected($event)"/>
      <div v-else
           class="horizontal-center">
        <suit-selector
            v-if="selectingSuit"
            class="suit-selector"
            :suits="allSuits"
            @select="suitSelected($event)"/>
      </div>
    </div>
    <div class="releasebutton-panel">
      <div
          class="button"
          style="font-family: Oswald"
          @click="relatedCardSelected(null)">
        {{ $t('releaseRelation') }}
      </div>
    </div>
  </div>
</template>

<script>
import {cards} from '@/logic/cards'
import CardSelector from "@/components/CardSelector";
import SuitSelector from "@/components/SuitSelector";
import {mapActions, mapGetters} from "vuex";
import Formatted from "@/components/Formatted";

export default {
  components: {Formatted, CardSelector, SuitSelector},
  props: {
    cardName: String,
  },
  data() {
    return {
      selectingSuit: false
    }
  },
  computed: {
    ...mapGetters(['allCards', 'cardsInSelectedPile', 'allSuits']),
    card() {
      return this.allCards.find(c => c.name === this.cardName)
    },
    selectableCards() {
      let res = []
      if (this.card.relationType === 'handCard' || this.card.relationType === 'handCardAndSuit')
        res = this.cardsInSelectedPile
      else
        res = this.allCards
      if (!this.card.relationToSelf)
        res = res.filter(c => c !== this.card)
      if (this.card.relationSuits) {
        const useOriginalSuits = this.card.relationType === 'gameCard' || this.card.name === cards.Doppelgaenger.name
        res = res.filter(c => this.card.relationSuits.indexOf(useOriginalSuits ? c.suit : c.scoreSuit) >= 0)
      }
      return res
    },
    showSuitMenuInCardSelector() {
      return this.card.relationType !== 'handCard' && this.card.relationType != 'handCardAndSuit'
    }
  },
  methods: {
    ...mapActions(['setCardRelation']),
    relatedCardSelected(relatedCard) {
      if (!relatedCard) {
        this.setCardRelation({card: this.card.name, relatedCard: null})
            .then(() => this.$router.go(-1))
      } else
        this.setCardRelation({card: this.card.name, relatedCard: relatedCard.name})
            .then(() => {
              if (this.card.relationType === 'handCard'
                  || this.card.relationType === 'gameCard')
                this.$router.go(-1)
              else if (this.card.relationType === 'handCardAndSuit')
                this.selectingSuit = true
            })
    },
    suitSelected(suit) {
      this.setCardRelation({card: this.card.name, relatedCard: this.card.relatedCard.name, relatedSuit: suit})
          .then(() => this.$router.go(-1))
    }
  },
  beforeMount() {
    this.selectingSuit = false
  }
}
</script>

<style scoped>
.heading-area {
  min-height: 10%;
  max-height: 20%;
}

.content-area {
  display: flex;
  justify-content: center;
  min-height: 80%;
  max-height: 90%;
}

.button {
  font-family: Luminari, serif;
  padding: 0.3rem;
  font-size: larger;
  border: 1px solid white;
  cursor: pointer;
  margin-left: 1rem;
}

.horizontal-center {
  display: flex;
  margin: auto;
}

.releasebutton-panel {
  position: absolute;
  bottom: 5%;
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: smaller;
}
</style>